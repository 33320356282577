import React, { useContext } from "react"
import { GlobalDataContext } from "../context/context"
import TransparentHeader from "../components/global/TransparentHeader"
import BaseLayout from "../components/global/BaseLayout"
import BlockContentPricipal from "../components/block/BlockContent_3"
import ValuesContent from "../components/values/Values_4"
import CallToAction2 from '../components/callToAction/CallToAction_2'



function About() {
  const { rpdata } = useContext(GlobalDataContext);

  return (
    <BaseLayout PageName={!rpdata?.spanishVersion?.isActive ? "Sobre Nosotros" : "About"}>
      <div className="md:max-w-full w-full">
        <TransparentHeader
          headertitle={!rpdata?.spanishVersion?.isActive ? "Sobre Nosotros" : "About"}
          Subheader={!rpdata?.spanishVersion?.isActive ? "Sobre Nosotros" : "About"}
          bgimg={`${rpdata?.gallery?.[10]}`}
          // para cambiar el svg del header busquemos en la carpeta css y buscamos el archivo de svgStyles
          // los estilo de svg para el header empiezan svgTranspareHeader seguido de un numero
          svgClass='svgTranspareHeader2'
        />

        <BlockContentPricipal
          title={!rpdata?.spanishVersion?.isActive ? "Sobre Nosotros" : "A Little About Us"}
          texts={rpdata?.dbAbout?.[0]?.text}
          listAbout
          bgImg1={rpdata?.gallery?.[7]}
          bgImg2={rpdata?.gallery?.[9]}
        />

        <ValuesContent 
        title={rpdata?.dbSlogan?.[2]?.slogan}
        />

        <div className="mb-20">
          <CallToAction2
            title={rpdata?.dbSlogan?.[4]?.slogan}
            subtitle={rpdata?.dbSlogan?.[0]?.slogan}
            phone={rpdata?.dbPrincipal?.phones?.[0]?.phone}
            bgImages={rpdata?.gallery?.[8]}
            texts={rpdata?.dbAbout?.[1]?.text}
          />
        </div>

      </div>
    </BaseLayout>
  );
}

export default About;
