import React from "react";

const TraslateHome = ({ cambiarId }) => {
    const id1 = "/paginaspanish/660aee2350c65970128ff2e9"; //Variable en español
    const id2 = "/paginas/660aebd450c65970128ff293"; //Variable en Ingles

    return (
        <div
            className="w-full h-screen relative flex items-center bg1"
        >
            <div className="relative flex flex-col items-center w-full">
                <img
                    src={'https://firebasestorage.googleapis.com/v0/b/clientesimages.appspot.com/o/Paginas%2F660aebd450c65970128ff293%2FiconAndLogo%2FAllStar%20Rent%20A%20Car%20-%20wh.png?alt=media&token=0a7682e2-5498-4007-9847-95cf3be6bd2e'}
                    alt="no-logo"
                    className="w-[80%] md:w-[30%] pb-5"
                />
                <h1 className="text-white capitalize pb-5">Sitio / Site</h1>
                <div className="flex">
                    <div className="mr-3">
                        <button
                            onClick={() => cambiarId({id: id1, l: 'es'})}
                            className="px-5 py-3 rounded-2xl bg-red-900"
                        >
                            Español
                        </button>
                    </div>
                    <div className="ml-3">
                        <button
                            onClick={() => cambiarId({id: id2, l: 'en'})}
                            className="px-5 py-3 rounded-2xl bg-red-600"
                        >
                            English
                        </button>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default TraslateHome