/* eslint-disable jsx-a11y/iframe-has-title */
import React, { useContext } from "react";
// import { FiMapPin } from "react-icons/fi";
// import { Link } from "react-router-dom";
import { GlobalDataContext } from "../../context/context";
import { Tab, Tabs, TabList, TabPanel } from "react-tabs";
import "react-tabs/style/react-tabs.css";

const Map = ({title}) => {
  const { rpdata } = useContext(GlobalDataContext);
  return (

    <div className="pb-20 md:pb-28">
      <div className=" bgColor1 py-5">
        {
          title ?
            <h2 className="text-center">
              {title}
            </h2>
            :
            <h2 className="text-center text-white">
              {
                !rpdata?.spanishVersion?.isActive ?
                  <span>
                  Brindamos Nuestros Servicios En La Zona Principal De Estados Unidos 
                  </span>
                  :
                  <span>
                  {rpdata?.labels?.general?.titleMap}
                  </span>
            }
            </h2>
        }
        {/* {
          rpdata?.dbPrincipal?.location.length > 2 ?
            <ul className="pt-4 pb-4 md:p-0 flex flex-wrap justify-between md:justify-center items-center ">
              {rpdata?.dbPrincipal?.location?.slice(1).map((item, index) => {
                return (
                  <Link to="/contact" key={index}>
                    <li className="py-2 px-3 flex items-center">
                      <FiMapPin fontSize={25} />
                      <span className="pl-2">{item.address}</span>
                    </li>
                  </Link>
                );
              })}
            </ul>
            : null
        } */}
      </div>
      {
        rpdata?.dbPrincipal?.location.length > 1 ?

          <Tabs>
            <TabList>
              <div className="flex flex-col md:flex-row md:flex-wrap justify-center text-center bg2 text-white">
                {
                  rpdata?.dbPrincipal?.location?.map((item, index) => {
                    return <Tab key={index}>{item.address}</Tab>;
                  })
                }
              </div>
            </TabList>
            {
              rpdata?.dbPrincipal?.location?.map((item, index) => {
                return (
                  <TabPanel key={index}>
                    <iframe
                      loading="lazy"
                      title="Cover Locations"
                      src={item.url}
                      style={{ width: "100%", height: "400px" }}
                    />
                  </TabPanel>
                )
              })
            }
          </Tabs>
          :
          <iframe
            src={rpdata?.dbPrincipal?.location?.[0].url}
            loading="lazy"
            title="Cover Locations"
            className="w-full h-[500px]"
          />

      }
    </div>
  )
}

export default Map
