import React, { useContext, useState, useEffect } from 'react'
import { GlobalDataContext } from '../../../context/context'
import { Spin as Hamburger } from 'hamburger-react'
import MenuList from "./MenuList"
import IconsRedes from "../IconRedes"
import { HiMail } from 'react-icons/hi'
import { ImPhone } from 'react-icons/im'
import { useLocation } from 'react-router'




const HeaderContent = ({ centerLogo, cambiarid }) => {
    const { id } = JSON.parse(localStorage.getItem("language"))

    console.log(id)

    const id1 = "/paginaspanish/660aee2350c65970128ff2e9"; //Variable en español
    const id2 = "/paginas/660aebd450c65970128ff293"; //Variable en Ingles

    let location = useLocation();
    console.log(location.pathname)

    const cambiarLang = (obj) => {
        localStorage.setItem("language", JSON.stringify(
            obj
        ))

        if (location.pathname === '/') {
            window.history.pushState({}, null, "/");
            window.location.reload(false);
        }
        if (location.pathname === '/sobre-nosotros') {
            window.history.pushState({}, null, "/about");
            window.location.reload(false);
        }
        if (location.pathname === '/about') {
            window.history.pushState({}, null, "/sobre-nosotros");
            window.location.reload(false);
        }
        if (location.pathname === '/services') {
            window.history.pushState({}, null, "/servicios");
            window.location.reload(false);
        }
        if (location.pathname === '/servicios') {
            window.history.pushState({}, null, "/services");
            window.location.reload(false);
        }
        if (location.pathname === '/galeria') {
            window.history.pushState({}, null, "/gallery");
            window.location.reload(false);
        }
        if (location.pathname === '/gallery') {
            window.history.pushState({}, null, "/galeria");
            window.location.reload(false);
        }
        if (location.pathname === '/contactenos') {
            window.history.pushState({}, null, "/contact");
            window.location.reload(false);
        }
        if (location.pathname === '/contact') {
            window.history.pushState({}, null, "/contactenos");
            window.location.reload(false);
        }
        return null
    }


    const { rpdata } = useContext(GlobalDataContext)

    // estado del menu
    const [isOpen, setOpen] = useState(false)

    const [widthWindows, setwidthWindows] = useState(window.innerWidth)


    const sizeWindows = () => {
        setwidthWindows(window.innerWidth)
    }
    useEffect(() => {
        window.addEventListener('resize', sizeWindows)
        return () => {
            window.removeEventListener('resize', sizeWindows)
        }
    })

    const logo1 = 'https://firebasestorage.googleapis.com/v0/b/clientesimages.appspot.com/o/Paginas%2F660aebd450c65970128ff293%2FiconAndLogo%2FAllStar%20Rent%20A%20Car%20-%20wh.png?alt=media&token=0a7682e2-5498-4007-9847-95cf3be6bd2e'

    return (
        <header>
            {
                rpdata?.simpleWidgets?.[3]?.activo ?
                    null
                    :
                    <section className={`flex items-center shadow-xl ${widthWindows > 560 ? 'bg-transparent' : 'bgGradient1'}`}>
                        <div className="flex items-center justify-between w-full">
                            <div className="w-70% hidden md:block">
                                {
                                    centerLogo ?
                                        <div className="px-6 flex gap-4 justify-around items-center">
                                            <div className='flex border-gray-400 border-r-2'>
                                                {rpdata?.dbPrincipal?.phones.map((phone, index) => {
                                                    return (
                                                        <a
                                                            key={index}
                                                            href={`tel:+1${phone.phone}`}
                                                            className="flex items-center justify-center gap-2 text-[18px] text-black hover:text-sky-600 ease-in-out duration-500"
                                                        >
                                                            <ImPhone fontSize={"14px"} />
                                                            <span>
                                                                {phone.phone}
                                                                <span className="pl-2">{phone.name}</span>
                                                            </span>
                                                        </a>
                                                    );
                                                })}
                                            </div>
                                            <div className='flex gap-4 items-center'>
                                                {
                                                    rpdata?.dbPrincipal?.emails?.map((email, index) => {
                                                        return (
                                                            <li
                                                                key={index}
                                                                className={`flex items-center gap-3`}
                                                            >
                                                                <a
                                                                    href={`mailto:${email.email}`}
                                                                    className='flex items-center gap-3 ease-in-out duration-500 text-[18px] hover:text-blue-400'
                                                                >
                                                                    <HiMail />
                                                                    <span>{email.email}</span>
                                                                </a>
                                                            </li>
                                                        )
                                                    })
                                                }
                                            </div>

                                        </div>
                                        :
                                        <MenuList classes={'w-full flex justify-start gap-5 px-14'} />
                                }
                            </div>
                            <div className={`p-5 md:p-10 w-full md:w-[30%] flex flex-col md:flex-row items-start justify-start md:justify-end gap-0 md:gap-5 ${widthWindows > 560 ? 'bgGradient1' : 'bg-transparent'}`}>
                                {
                                    rpdata?.dbSocialMedia?.redes.length > 0 ?
                                        <span className='capitalize font-semibold text-white'>{
                                            !rpdata?.spanishVersion?.isActive ? 'Siguenos :' : 'Follow Us On :'
                                          }</span>
                                        : null
                                }

                                <IconsRedes classes={'flex justify-center items-center gap-5'} />
                                <div className='flex'>
                                    <button
                                        onClick={() => cambiarLang({id: id1, l: "es"})}
                                        className='text-white font-bold mr-2'
                                    >
                                        Español
                                    </button>
                                    <button
                                        onClick={() => cambiarLang({id: id2, l: "en"})}
                                        className='text-white font-bold ml-2'
                                    >
                                        English
                                    </button>
                                </div>
                            </div>
                        </div>

                        <div className={`md:hidden relative z-20 flex justify-center py-6 w-[20%] mx-auto bg-white`}>
                            <Hamburger toggled={isOpen} toggle={setOpen} />
                        </div>


                        {
                            isOpen ?
                                <div className='fixed bg-[#222] z-20 top-0 left-0 h-screen w-full ease-in-out duration-700'
                                >
                                    <div className={`md:hidden absolute top-1 right-0 z-30 flex justify-center py-6 w-[20%] mx-auto bg-white`}>
                                        <Hamburger toggled={isOpen} toggle={setOpen} />
                                    </div>
                                    <div className="flex flex-col justify-center items-center h-full w-full">
                                        <picture className="flex justify-center items-center">
                                            <img
                                                src= {logo1}
                                                alt='logo'
                                                className="w-[50%]  mb-10"
                                            />
                                        </picture>
                                        <div className='w-full'>
                                            <MenuList
                                                classes={'w-full  h-[55vh] overflow-y-auto text-center text-white font-semibold text-[19px] flex flex-col gap-y-5'}
                                            />
                                        </div>
                                        <div>
                                            <spa className='capitalize font-semibold text-white pt-10'>Follow us On: </spa>

                                            <IconsRedes classes={'flex justify-center items-center gap-5'} />
                                        </div>
                                    </div>

                                </div>
                                : null
                        }
                    </section>
            }

            <div className="relative">
                <div className="flex justify-center  items-center absolute top-5 right-0 left-0 z-10">
                    <div className="flex justify-around items-center gap-5">
                        <img
                            src={logo1}
                            alt="logo"
                            className="w-full md:w-[30%]"
                        />
                        <div className={centerLogo ? 'hidden md:block' : 'hidden'}>
                            <MenuList
                                classes={"w-full flex justify-start gap-5  text-white"}
                            />
                        </div>
                    </div>
                </div>
            </div>

        </header>
    )
}

export default HeaderContent